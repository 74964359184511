/**
 * api接口统一管理
 */
import {
  get
} from './http'

/** params是我们请求接口时携带的参数对象。而后调用了封装的post方法，post方法的第一个参数是接口地址，第二个参数是方法的params参数，
     即请求接口时携带的参数对象
 */
// 登录
export const index = (params, showLoading) => get('/api/index/data', params, showLoading)
