<template>
  <div class="index">
    <div class="index_header ta-c">
      <div
        class="fs-40 co-ffffff pdt-15"
        style="
          font-family: Microsoft YaHei;
          text-shadow: 0px 0px 5px #00e7f8;
          line-height: 3.25rem;
          letter-spacing: 0.625rem;
        "
      >
        祥云会侨数据看板
      </div>
      <div class="index_tab">
        <span
          v-for="(item, index) in itemsList"
          :key="index"
          :style="date_type == index ? 'color: #00e7f8' : 'color: #99a1ab'"
          @click="handleTab(index)"
        >
          {{ item.title }}
        </span>
      </div>
    </div>
    <!-- 第一行框 -->
    <div class="banner fl-jsb-ac pd-30">
      <!-- 1.1 -->
      <div class="banner_item">
        <img class="banner_img" src="../../assets/image/border.png" alt="" />
        <img class="banner_img2" src="../../assets/image/border.png" alt="" />
        <div class="fl-jsb-ac pd-12">
          <div class="banner_box">
            <div id="myChart"></div>
            <span class="user_num">用户数量</span>
          </div>
          <div class="rate-box fl-fd-jcsb-aic">
            <div class="circular">
              <img
                class="bg"
                src="../../assets/image/rate100.png"
                v-if="details.register_rate >= 90"
              />
              <img
                class="bg"
                src="../../assets/image/rate90.png"
                v-else-if="details.register_rate >= 80"
              />
              <img
                class="bg"
                src="../../assets/image/rate80.png"
                v-else-if="details.register_rate >= 70"
              />
              <img
                class="bg"
                src="../../assets/image/rate70.png"
                v-else-if="details.register_rate >= 60"
              />
              <img
                class="bg"
                src="../../assets/image/rate60.png"
                v-else-if="details.register_rate >= 50"
              />
              <img
                class="bg"
                src="../../assets/image/rate50.png"
                v-else-if="details.register_rate >= 40"
              />
              <img
                class="bg"
                src="../../assets/image/rate40.png"
                v-else-if="details.register_rate >= 30"
              />
              <img
                class="bg"
                src="../../assets/image/rate30.png"
                v-else-if="details.register_rate >= 20"
              />
              <img
                class="bg"
                src="../../assets/image/rate20.png"
                v-else-if="details.register_rate >= 10"
              />
              <img class="bg" src="../../assets/image/rate10.png" v-else />
              <span class="circular_content"
                >{{ details.register_rate || 0 }}%</span
              >
            </div>
            <div class="circular_title mt-20">注册率</div>
          </div>
        </div>
      </div>
      <!-- 1.2 -->
      <div class="banner_item">
        <img class="banner_img" src="../../assets/image/border.png" alt="" />
        <img class="banner_img2" src="../../assets/image/border.png" alt="" />
        <div class="item_box">
          <div class="co-ffffff fs-18 fl-jsb-ac">
            <span>累计注册用户(人)</span>
            <div class="fs-14">
              <span>昨日新增：{{ details.perfect.yesterday || 0 }}</span>
              <span class="ml-30">{{ today }}</span>
            </div>
          </div>
          <div class="mt-20">
            <numBox :num="details.perfect.total || 0"></numBox>
          </div>
        </div>
        <div class="item_box">
          <div class="co-ffffff fs-18 fl-jsb-ac">
            <span>系统用户数据量(人)</span>
            <div class="fs-14">
              <span>昨日新增：{{ details.user.yesterday || 0 }}</span>
              <span class="ml-30">{{ today }}</span>
            </div>
          </div>
          <div class="mt-20">
            <numBox :num="details.user.total || 0"></numBox>
          </div>
        </div>
      </div>
      <!-- 1.3 -->
      <div class="banner_item">
        <img class="banner_img" src="../../assets/image/border.png" alt="" />
        <img class="banner_img2" src="../../assets/image/border.png" alt="" />
        <div class="title-box">
          <div class="fl-fd co-ffffff">
            <span class="fs-18">用户行为统计</span
            ><span class="fz-14 color-grey">User behavior statistics</span>
          </div>
          <div class="fl-aic-wrap">
            <div class="dataBox">
              <span class="co-00E7F8 fs-26">{{
                details.news.view_news || 0
              }}</span>
              <span class="fs-14 co-99A1AB">浏览新闻用户数</span>
            </div>
            <div class="dataBox">
              <span class="co-00E7F8 fs-26">{{
                details.news.view_time || 0
              }}</span>
              <span class="fs-14 co-99A1AB">在线时长(时)</span>
            </div>
            <div class="dataBox">
              <span class="co-00E7F8 fs-26">{{ details.news.news || 0 }}</span>
              <span class="fs-14 co-99A1AB">发布新闻数</span>
            </div>
            <div class="dataBox">
              <span class="co-00E7F8 fs-26">{{
                details.activity.view_activity || 0
              }}</span>
              <span class="fs-14 co-99A1AB">活动报名用户数</span>
            </div>
            <div class="dataBox">
              <span class="co-00E7F8 fs-26">{{
                details.activity.activity_time || 0
              }}</span>
              <span class="fs-14 co-99A1AB">在线时长(时)</span>
            </div>
            <div class="dataBox">
              <span class="co-00E7F8 fs-26">{{
                details.activity.activity || 0
              }}</span>
              <span class="fs-14 co-99A1AB">发布活动数</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二行框 -->
    <div class="banner fl-jsb-ac pd-30">
      <!-- 2.1 -->
      <div class="banner_item">
        <img class="banner_img" src="../../assets/image/border.png" alt="" />
        <img class="banner_img2" src="../../assets/image/border.png" alt="" />
        <div class="title-box">
          <div class="fl-fd co-ffffff">
            <span class="fs-18">每小时用户访问数</span
            ><span class="fz-14 color-grey"
              >Numder of users accessed per hour</span
            >
          </div>
          <!-- 折线图 -->
          <div id="visits"></div>
        </div>
      </div>
      <!-- 2.2 -->
      <div class="banner_item">
        <img class="banner_img" src="../../assets/image/border.png" alt="" />
        <img class="banner_img2" src="../../assets/image/border.png" alt="" />
        <div class="title-box">
          <div class="fl-fd co-ffffff">
            <span class="fs-18">活跃用户数(近30分钟)</span>
            <span class="fz-14 color-grey"
              >Number of active users in the last 30 mins</span
            >
          </div>
          <!-- 柱状图 -->
          <div id="active"></div>
        </div>
      </div>
      <!-- 2.3 -->
      <div class="banner_item">
        <img class="banner_img" src="../../assets/image/border.png" alt="" />
        <img class="banner_img2" src="../../assets/image/border.png" alt="" />
        <div class="title-box">
          <div class="fl-fd co-ffffff">
            <span class="fs-18">报名地区分布</span>
            <span class="fz-14 color-grey">Regional distribution of users</span>
          </div>
          <!-- 饼图 -->
          <div id="area"></div>
        </div>
      </div>
    </div>
    <!-- 第三行框 -->
    <div class="banner fl-jsb-ac pd-30">
      <!-- 3.1 -->
      <div class="banner_item2">
        <img class="banner_img" src="../../assets/image/border2.png" alt="" />
        <img class="banner_img2" src="../../assets/image/border2.png" alt="" />
        <div class="title-box">
          <div class="fl-fd co-ffffff">
            <span class="fs-18">用户搜索地区</span>
            <span class="fz-14 color-grey"
              >Distribution of transaction amoun</span
            >
          </div>
          <!-- 柱状图 -->
          <div id="search"></div>
        </div>
      </div>
      <!-- 3.2 -->
      <div class="banner_item2">
        <img class="banner_img" src="../../assets/image/border2.png" alt="" />
        <img class="banner_img2" src="../../assets/image/border2.png" alt="" />
        <div class="title-box">
          <div class="fl-fd co-ffffff">
            <span class="fs-18">实时用户</span>
            <span class="fz-14 color-grey">Real time user</span>
          </div>
          <!--  -->
          <div class="table-title">
            <div class="table-item">用户名</div>
            <div class="table-item">时间</div>
            <div class="table-item">操作</div>
            <div class="table-item flex-1">活动</div>
          </div>
          <div class="list color-grey">
            <div
              class="item"
              v-for="(item, index) in details.activity_log"
              :key="index"
            >
              <div class="table-item one-row">
                {{ item.nickname || "已删除用户" }}
              </div>
              <div class="table-item">{{ item.create_time }}</div>
              <div class="table-item">{{ item.type }}</div>
              <div class="table-item flex-1 one-row">
                {{ item.activity_title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let timer;
import numBox from "@/components/numBox.vue";
export default {
  components: { numBox },
  data() {
    return {
      details: {
        user: {},
        perfect: {},
        news: {},
        activity: {},
      },
      today: "",
      itemsList: [
        {
          title: "全部",
        },
        {
          title: "今天",
        },
        {
          title: "本周",
        },
      ],
      date_type: 0, // 0全部 1今天 2本周
    };
  },
  mounted() {
    clearTimeout(timer);
    this.getData();
  },
  methods: {
    getData() {
      clearTimeout(timer);
      this.api
        .index({
          type: 0,
          date_type: this.date_type, // 0全部 1今天 2本周
        })
        .then((res) => {
          let data = res.data;
          data.register_rate =
            (parseFloat(data.perfect.register_rate) * 100).toFixed(1) * 1;
          this.details = data;
          this.userAreaChart(data.area);
          this.visitsChart(data.visit);
          this.activeChart(data.active);
          this.areaChart(data.activity_area);
          this.searchChart(data.search);
          this.today = this.getCurrentTime("min");
          clearTimeout(timer);
          timer = setTimeout(() => {
            clearTimeout(timer);
            this.getData();
          }, 1000 * 10);
        }).catch(err=>{
          clearTimeout(timer);
          timer = setTimeout(() => {
            clearTimeout(timer);
            this.getData();
          }, 1000 * 10);
        });
    },
    // 图表
    userAreaChart(data) {
      try {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("myChart"));
        // 绘制图表
        var option;

        option = {
          title: {
            text: "用户数据",
            subtext: "user Data",
            left: "left",
            textStyle: {
              //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              color: "#fff",
              fontSize: 18,
              fontWeight: "500",
            },
            subtextStyle: {
              //副标题文本样式{"color": "#aaa"}
              fontSize: 12,
              color: "#B7C0CC",
            },
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "20%",
            x: "right",
            y: "center",
            itemWidth: 28, // 设置图例图形的宽
            itemHeight: 28, // 设置图例图形的高
            orient: "vertical",
            data: [],
          },

          series: [
            {
              name: "用户",
              type: "pie",
              radius: "58%",
              center: ["55%", "52%"],
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        option && myChart.setOption(option);
      } catch (error) {
        console.log(error);
      }
    },
    // 折线图
    visitsChart(data) {
      try {
        var chartDom = document.getElementById("visits");
        var myChart = this.$echarts.init(chartDom);
        var option;

        option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          grid: {
            left: "0",
            right: "4%",
            top: "8%",
            bottom: "2%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: data.dateArr,
            },
          ],
          yAxis: [
            {
              type: "value",
              minInterval: 1,
              axisLine: {
                show: true,
              },
              axisTick: {
                show: true,
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "访问数",
              type: "line",
              stack: "Total",
              smooth: true,
              lineStyle: {
                width: 1,
              },
              symbolSize: 6,
              areaStyle: {
                opacity: 0.8,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#00A3F8",
                  },
                  {
                    offset: 1,
                    color: "#00527C",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: data.series[0].data,
            },
          ],
        };
        option && myChart.setOption(option);
      } catch (error) {
        console.log(error);
      }
    },
    // 柱状图,30分钟活跃用户数
    activeChart(data) {
      try {
        var chartDom = document.getElementById("active");
        var myChart = this.$echarts.init(chartDom);
        var option;

        option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "0",
            right: "2%",
            top: "10%",
            bottom: "2%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: data.dateArr,
          },
          yAxis: {
            type: "value",
            min: 0,
            max: 100,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
          series: [
            {
              name: "活跃用户数(%)",
              type: "bar",
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#52FD68" },
                  { offset: 1, color: "#0A9CA4" },
                ]),
              },
              label: {
                show: true,
                position: "top",
                valueAnimation: true,
                formatter: "{c}%",
              },
              data: data.series[0].data,
            },
          ],
        };
        option && myChart.setOption(option);
      } catch (error) {
        console.log(error);
      }
    },
    areaChart(data) {
      try {
        var chartDom = document.getElementById("area");
        var myChart = this.$echarts.init(chartDom);
        var option;

        option = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "right",
            bottom: "5%",
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              name: "地区",
              type: "pie",
              radius: "50%",
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        option && myChart.setOption(option);
      } catch (error) {
        console.log(error);
      }
    },
    searchChart(data) {
      try {
        var chartDom = document.getElementById("search");
        var myChart = this.$echarts.init(chartDom);
        var option;

        option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "0",
            right: "2%",
            top: "10%",
            bottom: "2%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: data.dateArr,
          },
          yAxis: {
            type: "value",
            minInterval: 1,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
          series: [
            {
              type: "bar",
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#52FD68" },
                  { offset: 1, color: "#0A9CA4" },
                ]),
              },
              label: {
                show: true,
                position: "top",
                valueAnimation: true,
              },
              data: data.series[0].data,
            },
          ],
        };
        option && myChart.setOption(option);
      } catch (error) {
        console.log(error);
      }
    },
    handleTab(i) {
      this.date_type = i;
      this.getData();
    },
    getCurrentTime(type = "ms", connector = 1) {
      let mydate = new Date();
      let year = mydate.getFullYear();
      let month = mydate.getMonth() + 1;
      let day = mydate.getDate();
      let hour = mydate.getHours();
      let minute = mydate.getMinutes();
      let second = mydate.getSeconds();
      let time = mydate.getTime();
      // 格式化
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      hour = hour < 10 ? "0" + hour : hour;
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      let str = "";
      if (type == "year") {
        // 年
        str = year;
        if (connector == 2) {
          str += "年";
        }
      } else if (type == "month") {
        // 月
        if (connector == 1) {
          str = [year, month].join("-");
        } else if (connector == 3) {
          str = [year, month].join("/");
        } else if (connector == 2) {
          str = year + "年" + month + "月";
        }
      } else if (type == "day") {
        // 日
        if (connector == 1) {
          str = [year, month, day].join("-");
        } else if (connector == 3) {
          str = [year, month, day].join("/");
        } else if (connector == 2) {
          str = year + "年" + month + "月" + day + "日";
        }
      } else if (type == "min") {
        // 分
        if (connector == 1) {
          str = [year, month, day].join("-") + " " + [hour, minute].join(":");
        } else if (connector == 3) {
          str = [year, month, day].join("/") + " " + [hour, minute].join(":");
        } else if (connector == 2) {
          str =
            year +
            "年" +
            month +
            "月" +
            day +
            "日" +
            " " +
            [hour, minute].join(":");
        }
      } else if (type == "sec") {
        // 秒
        if (connector == 1) {
          str =
            [year, month, day].join("-") +
            " " +
            [hour, minute, second].join(":");
        } else if (connector == 3) {
          str =
            [year, month, day].join("/") +
            " " +
            [hour, minute, second].join(":");
        } else if (connector == 2) {
          str =
            year +
            "年" +
            month +
            "月" +
            day +
            "日" +
            " " +
            [hour, minute, second].join(":");
        }
      } else if (type == "ms") {
        str = time;
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  height: 100vh;
  background-color: #010f29;
}
.index_header {
  width: 100%;
  height: 8.5625rem;
  background-image: url("../../assets/image/index_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.index_tab {
  position: absolute;
  top: 5.9375rem;
  right: 1.875rem;
  color: #99a1ab;
  font-size: 0.875rem;
  span {
    margin-left: 1.875rem;
    cursor: pointer;
  }
}

.banner {
  background: #010f29;
  padding-top: 0;
  padding-bottom: 1.0625rem;
}

.banner_item {
  position: relative;
  width: 38rem;
  height: 18rem;
  background: #0e1b34;
  overflow: hidden;
}

.banner_item2 {
  position: relative;
  width: 57.5rem;
  height: 18rem;
  background: #0e1b34;
  overflow: hidden;
}

.banner_img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.banner_img2 {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.banner_box {
  width: 18.75rem;
  height: 100%;

  .user_num {
    font-size: 15px;
    position: absolute;
    bottom: 1rem;
    left: 10.5rem;
    color: #ffffff;
  }
}
#myChart {
  width: 336px;
  height: 264px;
  position: relative;
}
.rate-box {
  margin-top: 60px;
  margin-right: 60px;
}
.circular_title {
  margin-top: 32px;
  font-size: 0.9375rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 1.25rem;
  color: #ffffff;
}
.circular {
  width: 146px;
  height: 146px;
  border-radius: 50%;
  // border: 0.25rem solid #00a2f7;
  position: relative;
  .bg {
    display: block;
    width: 100%;
  }
  .circular_content {
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: #ffffff;
  }
}

.item_box {
  margin-bottom: 0.8rem;
  padding: 12px 0.75rem;
  box-sizing: border-box;
  .itemNum {
    width: 2.5rem;
    height: 3.4375rem;
    border-radius: 0.125rem;
    background: #00a3f8;
    color: #ffffff;
    font-size: 2.25rem;
    margin-right: 1.5rem;
    margin-top: 0.325rem;
  }
}

.dataBox {
  margin-top: 36px;
  width: 180px;
  height: 3.5rem;
  line-height: 1.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-box {
  padding-top: 12px;
  padding-left: 12px;
}
#visits {
  width: 100%;
  height: 220px;
}
#active {
  width: 100%;
  height: 220px;
}
#area {
  width: 80%;
  height: 220px;
}
#search {
  width: 100%;
  height: 220px;
}
.pl-12 {
  padding-left: 12px;
}
.flex-1 {
  flex: 1;
  overflow: hidden;
}
.table-title {
  margin-top: 6px;
  padding: 0 5px;
  display: flex;
  font-size: 14px;
  color: #ffffff;
}
.list {
  font-size: 14px;
  overflow: hidden;

  .item {
    padding: 0 5px;
    display: flex;
    font-size: 14px;
    &:nth-of-type(2n-1) {
      background-color: rgb(26, 39, 62);
    }
  }
}
.table-item {
  box-sizing: border-box;
  padding: 0 5px;
  width: 138px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
</style>