import store from '@/store/index'; // 引入vuex
import axios from 'axios'; // 引入axios
import {
  Loading,
  Message
} from 'element-ui';
import router from '../router/index';

// 环境的切换
if (process.env.NODE_ENV == "development") { // 开发
  // axios.defaults.baseURL = "https://xiangyunhuiqiao.togy.com.cn";
  axios.defaults.baseURL = "https://xiangyunhuiqiao.com";
} else if (process.env.NODE_ENV == "debug") { // 调试测试
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV == "production") { // 生产
  axios.defaults.baseURL = "https://xiangyunhuiqiao.com";
}


axios.defaults.timeout = 60000

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'

let loading;
//开始 加载loading
let startLoading = () => {
  loading = Loading.service({
    lock: true,
    text: '请稍后...', //加载动画的文字
    background: 'rgba(0, 0, 0, 0.7)', //加载动画的背景
  })
}
//结束 取消loading加载
let endLoading = () => {
  loading.close()
}

let needLoadingRequestCount = 0 //声明一个变量
let that = this

let showFullScreenLoading = () => {
  if (needLoadingRequestCount === 0) { //当等于0时证明第一次请求 这时开启loading
    startLoading();
  }
  needLoadingRequestCount++ //全局变量值++
}

let tryHideFullScreenLoading = () => {
  if (needLoadingRequestCount <= 0) return //小于等于0 证明没有开启loading 此时return
  needLoadingRequestCount-- //正常响应后 全局变量 --
  if (needLoadingRequestCount === 0) { //等于0 时证明全部加载完毕 此时结束loading 加载
    endLoading()
  }
}
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const token = store.state.userLogin.token;
    // 控制loading
    // if (config.showLoading != undefined || config.config.showLoading !=undefined) {
    // showFullScreenLoading();
    // }

    const token = JSON.parse(localStorage.getItem('token'))
    token && (config.headers.Authorization = token)
    return config
  },
  error => {
    tryHideFullScreenLoading()
    return Promise.error(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    tryHideFullScreenLoading()
    // 如果返回的状态码为200，说明接口请求成功
    // 否则的话抛出错误
    if (response.status === 200) {
      if (response.data.code == 4002) {
        Message('身份过期,请重新登录');
        // 清除token
        localStorage.removeItem('token')
        // store.commit('loginSuccess', null)
        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        setTimeout(() => {
          router.replace({
            path: '/',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
        }, 1000)
      } else if (response.data.code == 999) {
        Message.error(response.data.message);
      }
      return Promise.resolve(response)
    } else {
      Message.error(response.data.message);
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是2开头的的情况
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  error => {
    tryHideFullScreenLoading()
    if (error.response.status) {
      console.log(error, '错误');
      switch (error.response.status) {

        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          router.replace({
            path: '/',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
          break

          // 4002 token过期
          // 登录过期对用户进行提示
          // 清除本地token和清空vuex中token对象
          // 跳转登录页面
        case 4002:
          Message.error('登录过期，请重新登录')
          // 清除token
          localStorage.removeItem('token')
          store.commit('loginSuccess', null)
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.replace({
              path: '/',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }, 1000)
          break

          // 404请求不存在
        case 404:
          Message.error('网络请求不存在')
          break
          // 其他错误，直接抛出错误提示
        default:
          tryHideFullScreenLoading()
          this.$message({
            message: error.response.data.message,
            type: 'error'
          })

      }
      return Promise.reject(error.response)
    }
  }
)
window.axios = axios;
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, config = {
  showLoading: true
}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        config: config
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, config = {
  showLoading: true
}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        console.log(err)
        reject(err.data)
      })
  })
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params, config = {
  showLoading: true
}) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, config)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

/**
 * DELETE方法，对应DELETE请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function DELETE(url, params, config = {
  showLoading: true
}) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, {
        params: params,
        config: config
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}