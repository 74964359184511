<template>
  <div class="num-box">
    <div class="num" v-for="(item, index) in list" :key="index">
      {{ item }}
    </div>
  </div>
</template>
<script>
export default {
  name: "numBox",
  props: {
    num: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    num(val) {
      this.setList();
    },
  },
  data() {
    return {
      list: [0],
    };
  },
  mounted() {
    this.setList();
  },
  methods: {
    setList() {
      let txt = JSON.stringify(this.num);
      let num = 7 - txt.length;
      for (let index = 0; index < num; index++) {
          txt = '0' + txt;
      }
      let arr = txt.split("");
      let list = [];
      arr.forEach((element) => {
        list.push(element);
      });
      this.list = list;
    },
  },
};
</script>
<style scoped lang="less">
.num-box {
  display: flex;
}
.num {
  margin-right: 8px;
  width: 38px;
  height: 54px;
  line-height: 54px;
  background-color: #00a3f8;
  border-radius: 2px;
  font-size: 36px;
  text-align: center;
  color: #fff;
}
</style>