import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../src/utils/rem'
import './assets/less/style.less'
import * as api from './request/api'
import * as echarts from 'echarts'


Vue.config.productionTip = false
Vue.prototype.api = api

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$echarts = echarts